<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    single-edit
    authority="Users_Company"
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../storesStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      countryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'store',
        endpoint: 'store',
        route: 'stores',
        title: {
          single: this.$i18n.t('Store Add'),
          plural: this.$i18n.t('Stores'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'country.title', label: this.$i18n.t('country'), sortable: false },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [{ key: 'enabled', type: 'boolean' }]
    },

    filters() {
      return [
        {
          name: 'country',
          label: this.$t('country'),
          options: this.countryOptions,
          value: null,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
          value: null,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
}
</script>
